import React from 'react';
import _ from 'lodash';

import {htmlToReact} from '../utils';
import ActionLink from './ActionLink';
import { withPrefix } from 'gatsby';


export default class FooterTop extends React.Component {
  
    render() {
      console.log();
        return (
            <footer id="colophon" className="site-footer outer">
              <div className="inner">
                <div className="grid ">

                  <div className="grid-item mobile_col">
                    {_.get(this.props, 'pageContext.site.siteMetadata.footertop.mobile_icon', null) && (
                      <img className="mobile-icon" src={withPrefix(_.get(this.props, 'pageContext.site.siteMetadata.footertop.mobile_icon', null))} />
                    )}
                    {_.get(this.props, 'pageContext.site.siteMetadata.footertop.mobile_number', null) && (
                      <span className="copyright">{htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footertop.mobile_number', null))}</span>
                    )}
                  </div>

                  <div className="grid-item text-center business_col">
                    {_.get(this.props, 'pageContext.site.siteMetadata.footertop.content', null) && (
                      <span className="copyright">{htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footertop.content', null))}</span>
                    )} 
                  </div>

                  {_.get(this.props, 'pageContext.site.siteMetadata.footer.has_social', null) && (
                    <div className="grid-item flx-end soc_col">
                        <div className="social-links">
                            {_.map(_.get(this.props, 'pageContext.site.siteMetadata.footer.social_links', null), (action, action_idx) => (
                            <ActionLink key={action_idx} {...this.props} action={action} /> 
                            ))}
                        </div>
                    </div>
                  )}
                </div>
              </div>
            </footer>
        );
    }
}
