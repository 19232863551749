import React from 'react';
import _ from 'lodash';

import {htmlToReact, withPrefix} from '../utils';
import ActionLink from './ActionLink';

export default class Footer extends React.Component {
     
    render() {
        console.log(this.props)
        return (
           
            <footer id="colophon" className="site-footer outer footer_bottom">
              <div className="inner">
                <div className="site-footer-inside">
                  <p className="site-info copyright_col">
                    {_.get(this.props, 'pageContext.site.siteMetadata.footer.content', null) && (
                    <span className="copyright">{htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.content', null))}</span>
                    )}
                  </p>
                  {_.get(this.props, 'pageContext.site.siteMetadata.footer.has_develop_info', null) && (
                     <p className="develop-info ">
                        {_.get(this.props, 'pageContext.site.siteMetadata.footer.develop_info', null) &&(
                        <span className="copyright">{htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.develop_info', null))}</span>
                        )}
                    </p>
                  )} 
                  
                  {/* share fixed button */}
                  <div className="">
                    <div className="share_social">
                      <div className="social-links">
                          {_.map(_.get(this.props, 'pageContext.site.siteMetadata.footer.social_links', null), (action, action_idx) => (
                          <ActionLink key={action_idx} {...this.props} action={action} /> 
                          ))}
                      </div>
                      <div className="button-share">
                        Share
                        <div className="icon">
                          {_.get(this.props, 'pageContext.site.siteMetadata.footertop.share-icon', null) &&(
                            <img src={withPrefix(_.get(this.props, 'pageContext.site.siteMetadata.footertop.share-icon', null))} />
                          )}
                          
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </footer>
        );
    }
}
