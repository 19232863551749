import React from 'react';
import _ from 'lodash';

import {classNames, htmlToReact, withPrefix, Link, markdownify} from '../utils';
// import CtaButtons from './CtaButtons';
// Import css files
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default class AboutGrid extends React.Component {

    render() {
      var settings = {
        dots: true,
        nav:true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll:1
            }
          }
        ]
      };
        let section = _.get(this.props, 'section', null);
        console.log(section)
        return (
            <section id={_.get(section, 'section_id', null)} className={classNames('pb-4','block', 'block-grid', 'outer', {'has-header': _.get(section, 'title', null) || _.get(section, 'subtitle', null)})}>
              <div className="inner">
                {(_.get(section, 'title', null) || _.get(section, 'subtitle', null)) && (
                <div className="block-header inner-sm">
                  {_.get(section, 'title', null) && (
                  <h2 className="block-title">{_.get(section, 'title', null)}</h2>
                  )}
                  {_.get(section, 'subtitle', null) && (
                  <p className="block-subtitle">{htmlToReact(_.get(section, 'subtitle', null))}</p>
                  )}
                </div>
                )}
                {_.get(section, 'grid_items', null) && (
                <div className="block-content">
                  <div className={classNames('grid', {'grid-col-2': _.get(section, 'col_number', null) === 'two', 'grid-col-3': _.get(section, 'col_number', null) === 'three'})}>
                  
                   
                   
                  </div>
                </div>
                )}

              
                <Slider {...settings} className="arrow_top">
                    {_.map(_.get(section, 'grid_items', null), (item, item_idx) => (
                      <div key={item_idx} className="grid-item">
                        <div className="grid-item-inside">
                          {_.get(item, 'image', null) && (
                          <div className="grid-item-image">
                            <img src={withPrefix(_.get(item, 'image', null))} alt={_.get(item, 'image_alt', null)} />
                          </div>
                          )}
                          {_.get(item, 'title', null) && (
                          <h3 className="grid-item-title line-left">
                            {_.get(item, 'title_url', null) ? (
                            <Link to={withPrefix(_.get(item, 'title_url', null))}>{_.get(item, 'title', null)}</Link>
                            ) : 
                            _.get(item, 'title', null)
                            }
                          </h3>
                          )}
                          {_.get(item, 'title', null) && (
                          <h3 className="grid-item-title overlay_box line-left">
                            {_.get(item, 'title_url', null) ? (
                            <Link to={withPrefix(_.get(item, 'title_url', null))}>{_.get(item, 'title', null)}</Link>
                            ) : 
                            _.get(item, 'title', null)
                            }
                          </h3>
                          )}
                          {_.get(item, 'content', null) && (
                          <div className="grid-item-content">
                            {markdownify(_.get(item, 'content', null))}
                          </div>
                          )} 
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            </section>
            
        );
    }
}
