import React from 'react';
import _ from 'lodash';

import {classNames, htmlToReact, withPrefix, Link, markdownify} from '../utils';
import CtaButtons from './CtaButtons';

// Import css files
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class BannerSection extends React.Component {
  constructor(props) {
    super(props);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this); 
    this.state = {
      banner_play : true
    };
  } 
  play() {
    this.slider.slickPlay();
    this.setState ({ banner_play : true});
  }
  pause() {
    this.slider.slickPause();
    this.setState ({ banner_play : false});
  }
    render() {
      var settings = {
        dots: true,
        arrows:false,
        nav:true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,     
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '10px ',
        fade: true,
        afterChange: function(slider,index){
          console.log('slider ' + slider);
          if(slider == 3){
            document.querySelector('.home_section').classList.add('slider_single');
          }else{
            console.log('slider del');
            document.querySelector('.home_section').classList.remove('slider_single');
          }
        },
        responsive: [
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll:1
            }
          }
        ],
      };

        let section = _.get(this.props, 'section', null); 
        let sectionSingle  = "banner1";
        const { banner_play } = this.state;
        console.log(banner_play);

        return (
            <section  id={_.get(section, 'section_id', null)} className={classNames('block', 'block-grid', 'outer', {'has-header': _.get(section, 'title', null) || _.get(section, 'subtitle', null)}, 'slider_21', _.get(sectionSingle,null) )}>
              {/* <div className="inner"> */}
                {(_.get(section, 'title', null) || _.get(section, 'subtitle', null)) && (
                <div className="block-header inner-sm">
                  {_.get(section, 'title', null) && (
                  <h2 className="block-title">{_.get(section, 'title', null)}</h2>
                  )}
                  {_.get(section, 'subtitle', null) && (
                  <p className="block-subtitle">{htmlToReact(_.get(section, 'subtitle', null))}</p>
                  )}
                </div>
                )}
                {_.get(section, 'grid_items', null) && (
                <div className="block-content">
                  <Slider ref={slider => (this.slider = slider)} {...settings} className="home_section">
                      {_.map(_.get(section, 'grid_items', null), (item, item_idx) => (
                      <div key={item_idx} className="grid-item p-0">
                        <div className="d-flex slide_count" id={item_idx} cs-attrid={item_idx}>
                          {_.get(item, 'image', null) && (
                          <div className="grid-item-image">
                            <img src={withPrefix(_.get(item, 'image', null))} alt={_.get(item, 'image_alt', null)} />
                            
                          </div>
                          )}

                          <div  className={classNames('slider__inner', 'test' ,'d-flex',   _.get(item, 'single', null) == 'single')} >
                              {_.get(item, 'title', null) && (
                              <h3 className="grid-item-title line-left">
                              {_.get(item, 'title_url', null) ? (
                              <Link to={withPrefix(_.get(item, 'title_url', null))}>{htmlToReact(_.get(item, 'title', null))}</Link>
                              ) : 
                              _.get(item, 'title', null)
                              }                              
                              </h3>
                              )}
                              {_.get(item, 'content', null) && (
                              <div className="grid-item-content">
                              {markdownify(_.get(item, 'content', null))}
                              </div>
                              )}
                              {_.get(item, 'actions', null) && (
                              <div className="grid-item-buttons">
                              <CtaButtons {...this.props} actions={_.get(item, 'actions', null)} />
                              </div>
                              )}
                          </div>
                          
                        </div>
                      </div>
                      ))}
                  </Slider>
                </div>
                )}
              {/* </div> */}

                <div className={`slide_action`}>
                  <button className={`action  ${banner_play ? 'pause' : 'play'}`} onClick={banner_play ? this.pause : this.play}>

                  </button>
                </div>
            </section>
        );
    }
}
